/**
 * 指标类型枚举
 */
export enum METRIC {
    LCP = "lcp", // 最大内容绘制
    FP = "fp", // 首次绘制
    FCP = "fcp", // 首次内容绘制
    TTFB = "ttfb", // 首字节时间
    DOMREADY = "domReady", // DOM 准备时间
    LOAD = "load", // 页面加载时间
}

/**
 * 评级枚举
 */
export enum RATING {
    GOOD = "good", // 良好
    NEEDSIMPROVEMENT = "needs improvement", // 需要改进
    POOR = "poor", // 较差
}

/**
 * 页面类型枚举
 */
export enum PAGE_TYPE {
    HOME = "home", // 首页
    SPECSUBS = "specsubs", // 专题页
    PRODUCTDETAIL = "productDetail", // 商品详情页
    LENS = "lens", // 镜片流程页
    CART = "cart", // 购物车
    CHECKOUT = "checkout", // 结算页
    DEFALUT = "", // 默认
}

/**
 * 配置接口
 */
export interface Config extends CustomConfig {
    custom?: boolean; // 是否自定义上报
    pageType: PAGE_TYPE; // 页面类型
}

/**
 * 自定义配置接口
 */
export interface CustomConfig {
    instance?: any; // 路由实例
    pageUrl?: any; // 页面URL
    domain?: string; // 域名
}

/**
 * 上报数据接口
 */
export interface ReportData {
    projectName: string, // 项目名称
    pageType: PAGE_TYPE; // 页面类型
    pageUrl: string; // 页面URL
    data: MetricData[]; // 指标数据
    deviceInfo: DeviceInfo; // 设备信息
}

/**
 * 指标数据接口
 */
export interface MetricData {
    name: METRIC | string; // 指标名称
    value: number; // 指标值
    rating: RATING | string; // 评级
    type: string; // 类型
}

/**
 * 设备信息接口
 */
export interface DeviceInfo {
    browserType: string; // 浏览器类型
    browserVersion: string; // 浏览器版本
    deviceType: "pc" | "h5"; // 设备类型
}

/**
 * 回调函数类型
 */
export interface Callback {
    (...args: any[]): any;
}

/**
 * 自定义上报配置类型
 */
export type CustomReportConfig = Pick<ReportData, "pageType" | "pageUrl">;
