import { Config, PAGE_TYPE } from "./types";

export const config: Config = {
    pageType: PAGE_TYPE.DEFALUT,
};
export function setConfig(options: Config) {
    for (const key in config) {
        if (options[key as keyof Config]) {
            config[key as keyof Config] = options[key as keyof Config];
        }
    }
}
