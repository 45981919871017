"use client";
import React, { useEffect } from "react";
import webMonitor, { Config, PAGE_TYPE } from "@/src/utils/web-vitals";
import { usePathname } from "next/navigation";

interface Iprops extends Omit<Config, "pageType"> {
    pageType: keyof typeof PAGE_TYPE; // 页面类型
}

export default function WebMonitorComponent({ pageType }: Readonly<Iprops>) {
    // 通过url判断页面类型
    const pathname = usePathname();
    useEffect(() => {
        console.log("当前路由", pathname);
        webMonitor.init({
            pageType: PAGE_TYPE[pageType],
        });
    }, [pageType, pathname]);

    return null;
}
