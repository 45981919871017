// 性能数据上报
import {
    getCache,
    setCache,
    clearCache,
    setCustomCache,
    getCustomCache,
    clearCustomCache,
} from "./cache";
import { getPageUrl, getDeviceInfo, checkReportData } from "./utils";
import { config } from "./config";
import { Config, MetricData, ReportData, CustomReportConfig } from "./types";
import { addWebMetric } from "../../models/performance";

/**
 * @description: 上报数据
 * @param {MetricData} data 上报的性能数据
 * @param {boolean} isImmediate 是否立即上报
 * @param {boolean} custom 是否自定义上报模式
 * @param {object} customConfig 自定义上报模式参数
 */
export function report(
    data: MetricData[],
    isImmediate = false,
    custom = false,
    customConfig?: CustomReportConfig,
) {
    if (!checkReportData(data)) {
        console.log("未采集到完整数据,取消上报");
        return;
    }
    let reportData: any = {};
    let baseData = {
      projectName: 'firmoo-app',
      data,
      deviceInfo: getDeviceInfo(),
    }
    if (custom) {
        reportData = {
            ...customConfig,
            ...baseData,
        };
    } else {
        reportData = {
            ...config,
            ...baseData,
            pageUrl: getPageUrl(),
        };
    }

    if (isImmediate) {
        console.log("上报数据", reportData);
        reportWithFetch(reportData);
        return;
    }

    if ((window as any).requestIdleCallback) {
        (window as any).requestIdleCallback(() => {
            console.log(`上报数据`, reportData);
            reportWithFetch(reportData);
        });
    } else {
        setTimeout(() => {
            console.log("上报数据", reportData);
            reportWithFetch(reportData);
        });
    }
}

let timer: NodeJS.Timeout;
export function cacheReportData(data: MetricData, timeout: number = 3000) {
    setCache(data);
    clearTimeout(timer);
    timer = setTimeout(() => {
        const data = getCache();
        if (data.length) {
            report(data);
            clearCache();
        }
        clearCache();
    }, timeout);
}

let customTimer: NodeJS.Timeout;
export function cacheCustomReportData(
    data: MetricData,
    config: CustomReportConfig,
    timeout: number = 3000,
) {
    setCustomCache(data);
    clearTimeout(customTimer);
    customTimer = setTimeout(() => {
        const data = getCustomCache();
        if (data.length) {
            report(data, false, true, config);
            clearCustomCache();
        }
        clearCustomCache();
    }, timeout);
}

export function reportWithFetch(data: ReportData) {
    // 先注释，等后端修复好了后再开启
    // addWebMetric(data).then((res: any) => {
    //   if (res) {
    //     console.log('上报成功');
    //   }
    // }).catch(err => {
    //   console.log('上报失败', err);
    // })
}
