import { uniqBy } from "lodash";
import { MetricData } from "./types";

// 缓存数据
let cache: MetricData[] = [];

// 缓存自定义上报数据
let customCache: MetricData[] = [];
export function getCache() {
    return uniqBy(cache, "name");
}

export function setCache(data: MetricData) {
    cache.push(data);
}

export function clearCache() {
    cache = [];
}

export function getCustomCache() {
    return uniqBy(customCache, "name");
}

export function setCustomCache(data: MetricData) {
    customCache.push(data);
}

export function clearCustomCache() {
    customCache = [];
}
