import { getEnv, getSite } from "@/src/utils/configUtils";
import tiktokPixel from "./analysis/TiktokPixel";
import { cmpDiversion } from "@/src/components/LoadAnalysis/analysis/abTest";
import { addCmpScript } from "./analysis/gaLib/ga";
import { getLocalItem } from "@/lib/commonService";

const fbPixelLoad = (host: string) => {
    const PixelId0 = getEnv("PixelId0", host);
    if (!PixelId0) return;
    const head = document.getElementsByTagName("HEAD").item(0);
    const label = document.createElement("script");
    label.type = "text/javascript";
    label.innerHTML = `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', ${PixelId0});
  fbq('track', 'PageView');
`;
    head?.appendChild(label);
};

const tagManagerInit = (host: string) => {
    const GTM_ID = getEnv("GTM_ID", host);
    if (!GTM_ID) {
        return;
    }
    const head = document.getElementsByTagName("HEAD").item(0);
    const label = document.createElement("script");
    label.type = "text/javascript";
    label.innerHTML = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${GTM_ID}');
`;
    head?.appendChild(label);
};

const newTikTokPixelLoad = (host: string) => {
    const codeMap: Record<string, any> = {
        default: {
            code: `window.snaptr=function(){}`,
            id: "",
        },
        uk: {
            code: `!function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};    
            ttq.load('C2H352NMU8QAJ3JEIHH0');
            ttq.page();
          }(window, document, 'ttq');

          (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
            {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
            a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
            r.src=n;var u=t.getElementsByTagName(s)[0];
            u.parentNode.insertBefore(r,u);})(window,document,
            'https://sc-static.net/scevent.min.js');
            
            snaptr('init', '82b9b83b-cf93-4c19-abe3-5cd73770d692', {
            'user_email': 'liusucai@gmail.com'
            });

            snaptr('track', 'PAGE_VIEW');`,
            id: "C2H352NMU8QAJ3JEIHH0",
        },
        //     us: {
        //         code: `!function (w, d, t) {
        //   w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

        //   ttq.load('C3H6UMUPKKNB3CA8BUE0');
        //   ttq.page();
        // }(window, document, 'ttq');`,
        //         id: "C3H6UMUPKKNB3CA8BUE0",
        //     },
    };
    const site = getSite(host);
    const head = document.getElementsByTagName("HEAD").item(0);
    const { code, id } = codeMap[site] || codeMap.default;
    tiktokPixel.setId(id);
    let element = document.createElement("script");
    element.innerHTML = code;
    element.async = true;
    head?.appendChild(element);
};

const zendeskLoad = (host: string) => {
    const site = getSite(host);
    switch (site) {
        // 黑人站点
        case "fm":
            let script = document.createElement("script");
            script.id = "ze-snippet";
            script.async = true;
            script.src =
                "https://static.zdassets.com/ekr/snippet.js?key=b8a01e22-3998-4dd7-8111-cb9dc5fe26c3";
            document.body.appendChild(script);
            return;
    }
};

const obAdvLoad = (host: string) => {
    const site = getSite(host);
    switch (site) {
        case "us":
            const head = document.getElementsByTagName("HEAD").item(0);
            const label = document.createElement("script");
            label.type = "text/javascript";
            label.setAttribute("data-obct", "");
            label.innerHTML =
                "!function(_window, _document) {var OB_ADV_ID = '00602b31fc2be75295efb13d1f4d5910dc';if (_window.obApi) {var toArray = function (object) {return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];};_window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));return;}var api = _window.obApi = function () {api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);};api.version = '1.1';api.loaded = true;api.marketerId = OB_ADV_ID;api.queue = [];var tag = _document.createElement('script');tag.async = true;tag.src = '//amplify.outbrain.com/cp/obtp.js';tag.type = 'text/javascript';var script = _document.getElementsByTagName('script')[0];script.parentNode.insertBefore(tag, script);} (window, document);obApi('track', 'PAGE_VIEW');";
            head?.appendChild(label);
            return;
    }
};

const snaptrLoad = (host: string) => {
    const site = getSite(host);
    switch (site) {
        case "us":
            const customers_email_address = getLocalItem(
                "customers_email_address",
            );
            const head = document.getElementsByTagName("HEAD").item(0);
            const label = document.createElement("script");
            label.type = "text/javascript";
            label.innerHTML = `(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                  {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                  a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
                  r.src=n;var u=t.getElementsByTagName(s)[0];
                  u.parentNode.insertBefore(r,u);})(window,document,
                    'https://sc-static.net/scevent.min.js');
                    
                    snaptr('init', '01669e3b-a3b6-4682-83db-ad544f330edc', {
                    'user_email': '${customers_email_address}'
                    });
        
                    snaptr('track', 'PAGE_VIEW');`;

            head?.appendChild(label);
            return;
    }
};
const cmpLoad = (domain: string) => {
    const site = getSite(domain);
    const isCmp = cmpDiversion(site);
    if (!isCmp) return;
    addCmpScript();
};
export {
    fbPixelLoad,
    tagManagerInit,
    newTikTokPixelLoad,
    zendeskLoad,
    obAdvLoad,
    snaptrLoad,
    cmpLoad,
};
