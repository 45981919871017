import { getCookieItem } from "@/lib/commonService";
import { PC_COOKIE_NAME } from "@/config/constConf";
import { UAParser } from "ua-parser-js";
import { RATING, Callback, MetricData, METRIC, DeviceInfo } from "./types";

/**
 * 页面加载完成时触发回调函数
 * @param callback - 页面加载完成时的回调函数
 */
export function onLoad(callback: Callback) {
    const onLoadPage = (event: any) => {
        callback(event);
        window.removeEventListener("load", onLoadPage, true);
    };
    window.addEventListener("load", onLoadPage, true);
}

/**
 * 页面即将关闭时触发回调函数
 * @param callback - 页面即将关闭时的回调函数
 */
export function onBeforeunload(callback: Callback) {
    window.addEventListener("beforeunload", callback, true);
}

/**
 * 页面隐藏或离开时触发回调函数
 * @param callback - 页面隐藏或离开时的回调函数
 * @param once - 是否只触发一次回调函数
 */
export function onHidden(callback: Callback, once: boolean) {
    const onHiddenOrPageHide = (event: any) => {
        if (
            event.type === "pagehide" ||
            document.visibilityState === "hidden"
        ) {
            callback(event);
            if (once) {
                window.removeEventListener(
                    "visibilitychange",
                    onHiddenOrPageHide,
                    true,
                );
                window.removeEventListener(
                    "pagehide",
                    onHiddenOrPageHide,
                    true,
                );
            }
        }
    };

    window.addEventListener("visibilitychange", onHiddenOrPageHide, true);
    window.addEventListener("pagehide", onHiddenOrPageHide, true);
}

/**
 * 获取当前页面的URL
 * @returns 当前页面的URL
 */
export function getPageUrl(): string {
    return window.location.href;
}

/**
 * 获取设备信息
 * @returns 设备信息对象
 */
export function getDeviceInfo(): DeviceInfo {
    const parser = new UAParser();
    const browserVersion = parser.getBrowser().version ?? "";
    const browserType = parser.getBrowser().name ?? "";
    return {
        browserVersion,
        browserType,
        deviceType: getCookieItem(PC_COOKIE_NAME) === "1" ? "pc" : "h5",
    };
}

/**
 * 根据给定的值和范围，返回评级
 * @param value - 给定的值
 * @param min - 最小范围
 * @param max - 最大范围
 * @returns 评级
 */
export function getRating(value: number, min: number, max: number): RATING {
    if (value > max) {
        return RATING.POOR;
    } else if (value < min) {
        return RATING.GOOD;
    } else {
        return RATING.NEEDSIMPROVEMENT;
    }
}

/**
 * 检查报告数据是否完整
 * @param data - 报告数据数组
 * @returns 是否完整
 */
export function checkReportData(data: MetricData[]): boolean {
    const dataNames = data.map((item) => item.name);
    const metricNames = Object.values(METRIC);
    return metricNames.every((item) => dataNames.includes(item));
}

/**
 * 判断当前浏览器是否为Safari浏览器
 * @returns 是否为Safari浏览器
 */
export function isSafari(): boolean {
    return (
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent)
    );
}
