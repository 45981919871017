// 性能数据采集
import { getRating, isSafari } from "./utils";
import { METRIC, MetricData, Config } from "./types";
import { cacheCustomReportData, cacheReportData } from "./report";

export function handleReportData(data: MetricData) {
    if (data.value >= 0) {
        cacheReportData(data);
    }
}

export function handleCustomReportData(data: MetricData, config: Config) {
    if (data.value >= 0) {
        cacheCustomReportData(data, {
            pageType: config.pageType,
            pageUrl: config.pageUrl,
        });
    }
}

export function getFCP() {
    const entryHandler = (list: PerformanceObserverEntryList) => {
        for (const entry of list.getEntries()) {
            if (entry.name === "first-contentful-paint") {
                observer.disconnect();
                handleReportData({
                    name: METRIC.FCP,
                    value: Math.round(entry.startTime),
                    rating: getRating(entry.startTime, 1800, 3000),
                    type: "performance",
                });
            }
        }
    };
    const observer = new PerformanceObserver(entryHandler);
    observer.observe({ type: "paint", buffered: true });
}

export function getLCP() {
    // safari浏览器暂时不支持LCP
    if (isSafari()) {
        handleReportData({
            name: METRIC.LCP,
            value: 0,
            rating: getRating(0, 2500, 4000),
            type: "performance",
        });
    } else {
        const entryHandler = (list: PerformanceObserverEntryList) => {
            for (const entry of list.getEntries()) {
                observer.disconnect();
                handleReportData({
                    name: METRIC.LCP,
                    value: Math.round(entry.startTime),
                    rating: getRating(entry.startTime, 2500, 4000),
                    type: "performance",
                });
            }
        };
        const observer = new PerformanceObserver(entryHandler);
        observer.observe({ type: "largest-contentful-paint", buffered: true });
    }
}

export function getFP() {
    const entryHandler = (list: PerformanceObserverEntryList) => {
        for (const entry of list.getEntries()) {
            if (entry.name === "first-paint") {
                observer.disconnect();
                handleReportData({
                    name: METRIC.FP,
                    value: Math.round(entry.startTime),
                    rating: getRating(entry.startTime, 1000, 3000),
                    type: "performance",
                });
            }
        }
    };
    const observer = new PerformanceObserver(entryHandler);
    observer.observe({ type: "paint", buffered: true });
}

export function getTTFB() {
    const entryHandler = (list: PerformanceObserverEntryList) => {
        const entry = list.getEntries()[0] as PerformanceNavigationTiming | any;
        const value = entry.responseStart - entry.activationStart;
        handleReportData({
            name: METRIC.TTFB,
            value: Math.round(value),
            rating: getRating(value, 800, 1800),
            type: "performance",
        });
    };
    const observer = new PerformanceObserver(entryHandler);
    observer.observe({ type: "navigation", buffered: true });
}

export function getDomReady() {
    const entryHandler = (list: PerformanceObserverEntryList) => {
        const entry = list.getEntries()[0] as PerformanceNavigationTiming;
        const value = entry.domContentLoadedEventEnd - entry.fetchStart;
        handleReportData({
            name: METRIC.DOMREADY,
            value: Math.round(value),
            rating: getRating(value, 3000, 5000),
            type: "performance",
        });
    };
    const observer = new PerformanceObserver(entryHandler);
    observer.observe({ type: "navigation", buffered: true });
}

export function getLoad() {
    const entryHandler = (list: PerformanceObserverEntryList) => {
        const entry = list.getEntries()[0];
        handleReportData({
            name: METRIC.LOAD,
            value: Math.round(entry.duration),
            rating: getRating(entry.duration, 5000, 10000),
            type: "performance",
        });
    };
    const observer = new PerformanceObserver(entryHandler);
    observer.observe({ type: "navigation", buffered: true });
}

export function performance() {
    getFCP();
    getLCP();
    getTTFB();
    getFP();
    getDomReady();
    getLoad();
}

export function customGetFCP(options: Config) {
    const entryHandler = (list: PerformanceObserverEntryList) => {
        for (const entry of list.getEntries()) {
            if (entry.name === "first-contentful-paint") {
                observer.disconnect();
                handleCustomReportData(
                    {
                        name: METRIC.FCP,
                        value: Math.round(entry.startTime),
                        rating: getRating(entry.startTime, 1800, 3000),
                        type: "performance",
                    },
                    options,
                );
            }
        }
    };
    const observer = new PerformanceObserver(entryHandler);
    observer.observe({ type: "paint", buffered: true });
}

export function customGetLCP(options: Config) {
    // safari浏览器暂时不支持LCP
    if (isSafari()) {
        handleCustomReportData(
            {
                name: METRIC.LCP,
                value: 0,
                rating: getRating(0, 2500, 4000),
                type: "performance",
            },
            options,
        );
    } else {
        const entryHandler = (list: PerformanceObserverEntryList) => {
            for (const entry of list.getEntries()) {
                observer.disconnect();
                handleCustomReportData(
                    {
                        name: METRIC.LCP,
                        value: Math.round(entry.startTime),
                        rating: getRating(entry.startTime, 2500, 4000),
                        type: "performance",
                    },
                    options,
                );
            }
        };
        const observer = new PerformanceObserver(entryHandler);
        observer.observe({ type: "largest-contentful-paint", buffered: true });
    }
}

export function customGetFP(options: Config) {
    const { instance } = options;
    const handleLoad = function () {
        const navigationEntries =
            window.performance.getEntriesByType("navigation");
        if (navigationEntries.length > 0) {
            const { responseEnd, fetchStart } =
                navigationEntries[0] as PerformanceNavigationTiming;
            const value = responseEnd - fetchStart;
            handleCustomReportData(
                {
                    name: METRIC.FP,
                    value: Math.round(value),
                    rating: getRating(value, 1000, 3000),
                    type: "performance",
                },
                options,
            );
        }

        instance.events.off("routeChangeComplete", handleLoad);
    };
    instance.events.on("routeChangeComplete", handleLoad);
}

export function customGetTTFB(options: Config) {
    const { instance } = options;
    const handleLoad = function () {
        const { responseStart, navigationStart } = window.performance.timing;
        const value = responseStart - navigationStart;
        handleCustomReportData(
            {
                name: METRIC.TTFB,
                value: Math.round(value),
                rating: getRating(value, 800, 1800),
                type: "performance",
            },
            options,
        );

        instance.events.off("routeChangeComplete", handleLoad);
    };
    instance.events.on("routeChangeComplete", handleLoad);
}

export function customGetDomReady(options: Config) {
    const { instance } = options;
    const handleLoad = function () {
        const navigationEntries =
            window.performance.getEntriesByType("navigation");
        if (navigationEntries.length > 0) {
            const { domContentLoadedEventEnd, fetchStart } =
                navigationEntries[0] as PerformanceNavigationTiming;
            const value = domContentLoadedEventEnd - fetchStart;
            handleCustomReportData(
                {
                    name: METRIC.DOMREADY,
                    value: Math.round(value),
                    rating: getRating(value, 3000, 5000),
                    type: "performance",
                },
                options,
            );
        }

        instance.events.off("routeChangeComplete", handleLoad);
    };
    instance.events.on("routeChangeComplete", handleLoad);
}

export function customGetLoad(options: Config) {
    const { instance } = options;
    const handleLoad = function () {
        const navigationEntries =
            window.performance.getEntriesByType("navigation");
        if (navigationEntries.length > 0) {
            const { loadEventStart, fetchStart } =
                navigationEntries[0] as PerformanceNavigationTiming;
            const value = loadEventStart - fetchStart;
            handleCustomReportData(
                {
                    name: METRIC.LOAD,
                    value: Math.round(value),
                    rating: getRating(value, 5000, 10000),
                    type: "performance",
                },
                options,
            );
        }

        instance.events.off("routeChangeComplete", handleLoad);
    };
    instance.events.on("routeChangeComplete", handleLoad);
}

export function customPerformance(options: Config) {
    customGetFCP(options);
    customGetLCP(options);
    customGetTTFB(options);
    customGetFP(options);
    customGetDomReady(options);
    customGetLoad(options);
}
