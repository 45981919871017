import { getVistorId } from "@/src/models/customerShow";

//镜片流程页面A/B Test 浏览者分流
const diversionPercentages: Record<string, any> = {
    cl: 5,
    mx: 5,
    de: 8,
    us: 5,
    fr: 0,
    pt: 0,
    it: 0,
};

interface ILensPageDiversionProps {
    site: string;
    products_model: string;
    master_categories_id: number;
}

export function LensPageDiversion(
    parms: Partial<ILensPageDiversionProps> = {},
) {
    const { site, products_model, master_categories_id } = parms;

    if (!site || !products_model || !master_categories_id) {
        return true;
    }

    if (products_model === "BJ6602") {
        return true;
    }

    if (master_categories_id !== 1 && master_categories_id !== 6) {
        return true;
    }

    const visitorId = getVistorId();
    const _visitor_in_persent = visitorId % 10;
    const oldPercent = diversionPercentages[site];
    if (oldPercent && _visitor_in_persent > oldPercent - 1) {
        return false;
    }

    return true;
}

//重复支付分流
export function prePayDiversion(site: string) {
    if (site === "fr" || site === "us") {
        return true;
    }

    return false;
}

export function oceanPayDiversion() {
    const visitorId = getVistorId();
    console.log("visitorId", visitorId, visitorId % 2 === 0);
    return visitorId % 2 === 0;
}

export function googlePayDiversion() {
    if (!window) {
        return false;
    }
    return true;
}

export function applePayDiversion() {
    if (!window) {
        return false;
    }
    if (window.location.search.indexOf("applepay=test") > -1) {
        return true;
    }

    return true;
}

export function deGooglePayDiversion() {
    if (!window) {
        return false;
    }
    return true;
    // let prePercent = 2;
    // const visitorId = getVistorId();
    // const _visitor_in_persent = visitorId % 10;
    // return _visitor_in_persent <= prePercent - 1;
}

export function deApplePayDiversion() {
    if (!window) {
        return false;
    }
    if (window.location.search.indexOf("applepay=test") > -1) {
        return true;
    }
    return true;
    // let prePercent = 2;
    // const visitorId = getVistorId();
    // const _visitor_in_persent = visitorId % 10;
    // return _visitor_in_persent <= prePercent - 1;
}

export function useePayDiversion() {
    return true;
    // return (
    //   typeof window !== 'undefined' &&
    //   window.location.href.includes('new_useepay')
    // );
}

export function ukPacyPayDiversion() {
    let prePercent = 5;
    const visitorId = getVistorId();
    const _visitor_in_persent = visitorId % 10;
    return _visitor_in_persent <= prePercent - 1;
}

export function pacyPayDiversion() {
    return true;
    // setSession('new_pacypay', '-')
    // sessionStorage.removeItem('new_pacypay')
    // return typeof window !== 'undefined' && getSession('new_pacypay');
}

// cmp分流
export function cmpDiversion(site: string) {
    const siteDiversion: Record<string, number> = {
        es: 0,
        uk: 0,
        de: 0,
        fr: 0,
        pt: 0,
        it: 0,
        pl: 0,
        ro: 0
    };
    if (site in siteDiversion) {
        const visitorId = getVistorId();
        const _visitor_in_persent = visitorId % 10;
        let oldPercent = siteDiversion[site];
        return _visitor_in_persent > oldPercent - 1;
    }
    return false;
}

export function lensOrderDiversion(site: string) {
    if (!window) {
        return false;
    }

    if (!window.process.env.LC_ORDER_DIVERSION) {
        return false;
    }

    let oldPercent = Number(window.process.env.LC_ORDER_DIVERSION);
    const visitorId = getVistorId();
    const _visitor_in_persent = visitorId % 10;

    return _visitor_in_persent <= oldPercent - 1;
}
