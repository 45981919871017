// Create a Providers component to wrap your application with all the components requiring 'use client', such as next-nprogress-bar or your different contexts...
"use client";

import { AppProgressBar } from "next-nprogress-bar";

const ProgressBar = ({ children }: any) => {
    return (
        <>
            {children}
            <AppProgressBar
                height="3px"
                color="#2ea7e0"
                options={{ showSpinner: false }}
                shallowRouting
            />
        </>
    );
};

export default ProgressBar;
