"use client";
/**
 * @file 广告埋点组件
 */
import { useEffect, useState } from "react";
import CriteoAd from "@/src/components/LoadAnalysis/analysis/criteo";
import acquisition from "@/src/components/LoadAnalysis/analysis/DataAcquisition";
import { getEnv } from "@/src/utils/configUtils";
import { AddFlow, flowSpcice } from "@/src/utils";
import { getLocalItem } from "@/lib/commonService";
import {
    fbPixelLoad,
    tagManagerInit,
    zendeskLoad,
    obAdvLoad,
    snaptrLoad,
    newTikTokPixelLoad,
    cmpLoad,
} from "./utils";
import newTiktokPixel from "./analysis/newTiktokPixel";
import pintrkPixel from "./analysis/PintrkPixel";
import { includeGaPath } from "./analysis/gaLib/ga";
import useAnalysis from "@/src/hooks/useAnalysis";
import { getVistorId } from "@/src/models/customerShow";
import { isLogin } from "@/src/models/loginModel";
import { getUid } from "@/src/models/personalize";

/**
 * 统一的分析埋点初始化入口
 * @returns
 */
const LoadAnalysis = (props: any) => {
    const { host, route } = props;
    const [GTM_ID, setGTM_ID] = useState();
    const { GoogleAnalytics } = useAnalysis();
    useEffect(() => {
        setTimeout(() => {
            const GTMId = getEnv("GTM_ID", host);
            GTMId && setGTM_ID(GTMId);

            // tiktok
            newTiktokPixel.init(host);
            newTikTokPixelLoad(host);

            // ck
            acquisition.init(host);
            // pintrk
            pintrkPixel.init(host);
            // fb
            fbPixelLoad(host);
            // tagManager
            tagManagerInit(host);

            // ze-snippet
            zendeskLoad(host);
            obAdvLoad(host);
            snaptrLoad(host);

            CriteoAd.init();
            const visitorId = getVistorId();
            if (visitorId) {
                if (!isLogin()) {
                    const uid = getUid();
                    const userId = uid ? "U" + uid : "V" + visitorId;
                    acquisition.login("UserId ", userId);
                }
            }
            // 流量归因
            const flow1 = getLocalItem("flow1");
            flow1 && flowSpcice.init(false, flow1);

            if (!includeGaPath("/" + route)) {
                GoogleAnalytics();
            }
        }, 0);
        setTimeout(() => {
            AddFlow.init();
            if (self.process) {
                cmpLoad(host);
            }
        }, 1000);
    }, []);

    return (
        <>
            <noscript>
                <img
                    alt=""
                    height="1"
                    width="1"
                    style={{ borderStyle: "none" }}
                    src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/866886311/?guid=ON&amp;script=0"
                />
            </noscript>
            {GTM_ID && (
                <noscript>
                    <iframe
                        src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
                        height="0"
                        width="0"
                        style={{ display: "none", visibility: "hidden" }}
                    ></iframe>
                </noscript>
            )}
        </>
    );
};

export default LoadAnalysis;
