import { performance, customPerformance } from "./performance";
import { setConfig } from "./config";
import { Config } from "./types";
export * from "./types";

const webMonitor = {
    init(options: Config) {
        // 自定义采集（路由跳转情况需要用这种方式）
        if (options.custom && options.instance) {
            customPerformance(options);
            return;
        }
        // 默认采集
        setConfig(options);
        performance();
    },
};

export default webMonitor;
